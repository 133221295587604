<template>
  <div class="container-Politica">
        <!-- -->
      <section class="container-fluid">
        <section class="container">
          <div class="row">
            <div class="col"><br>
            <h1 style="color:#400041;" class="bold">Termos de uso</h1><br>
            </div>
            </div>
        </section>

        <div class="row">

          <div class="col-sm-12 fundo-roxo quadro">
            <br>
            <div class="espacamento txt-white">
           
            <p align="center" style="text-align: left"><strong> </strong>
  <span style="text-align: left"></span>Seja bem-vindo à <strong>TARIMBA NA COZINHA</strong>.</p><p>Este Termo regulamenta o uso do site, serviços e produtos  oferecidos e fornecidos pela Culinar Conteúdos e Formação em Culinária Ltda., com  sede em São Paulo, SP, na rua Américo Brasiliense, 1.490, 2º andar, sala 24,  Chácara Santo Antônio, São Paulo, SP, CEP 04715-002, inscrita no CNPJ/MF sob nº  35.612.467/0001-77, doravante denominada <strong>TARIMBA NA COZINHA</strong>.<br>
</p>
<p>&nbsp;</p>
<p><strong>1. ACEITAÇÃO DO TERMO DE USO E DA POLÍTICA DE PRIVACIDADE</strong></p>
<p>&nbsp;</p>
<p>A utilização do site por você e seu aceite implica na aceitação  integral e plena deste <em><u>Termo de Uso</u></em>, assim como eventuais alterações. Deste modo, é importante ter o conhecimento sobre seus  conteúdos de forma clara e atualizada. </p>
<p>A utilização do site também se submete aos demais termos,  avisos, regulamentos de uso, instruções e leis que versam sobre o assunto em  questão, previamente disponibilizadas ao usuário para conhecimento, instrumentos esses que garantem a utilização saudável e segura do site.</p>
<p>Em caso de não concordância com os termos, entre em contato, ou  descontinue o uso do site.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>2. OBJETO,  CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO SITE</strong><strong>&nbsp;</strong></p>
<p>&nbsp;</p>
<p>A <strong>TARIMBA NA COZINHA</strong>, por meio do seu site, disponibiliza  informações culinárias selecionadas para o público em geral. <br>
Os visitantes livres, ou seja, aqueles não cadastrados, tem  acesso ao conteúdo do blog e podem testar o  mecanismo de busca de receitas por ingrediente, porém, não tem acesso ao  detalhamento das receitas. <br>
Aos visitantes cadastrados é possível usar o mecanismo de busca sem limites, além do acesso ao conteúdo das receitas.<br>
Além do conteúdo do Blog dos tarimbados, e do acesso completo ao repertório de receitas, através da sessão<strong> ENCONTRE RECEITAS</strong>, a <strong>TARIMBA NA COZINHA</strong> oferece ainda os seguintes serviços aos seus usuários cadastrados:
</p>
<ol>
  <li>Sessão <strong>COMPARTILHE SUA RECEITA</strong> , na qual os  usuários podem cadastrar e propor o compartilhamento das suas receitas com o  restante da rede de usuários.</li>


  <li>Sessão <strong>RECEITAS A TESTAR</strong>, na qual os usuários  podem salvar as receitas que identificaram na plataforma e que eventualmente  queiram executar em outro momento, voltando a elas a qualquer tempo, bastando  para isso acessar essa sessão.</li>

  <li>Sessão <strong>SEU LIVRO DE RECEITAS</strong>, na qual os usuários  salvam as receitas que já executaram, ou que simplesmente avaliaram  positivamente e que querem acessar comais frequência.</li>

  <li>Sessão <strong>LISTA DE COMPRAS</strong>, na qual os usuários  podem organizar suas compras de ingredientes a partir das receitas que desejam  realizar a qualquer tempo, podendo salvar ou encaminhar essas listas da forma  que quiserem, seja por e-mail, WhatsApp ou qualquer outra plataforma.</li>

  <li>Nas páginas das <strong>RECEITAS</strong> os usuários têm ainda a oportunidade de registrar sua<strong> AVALIAÇÃO E COMENTÁRIOS</strong> da receita em questão, sendo esses comentários mediados pelos responsáveis pela <strong>TARIMBA NA COZINHA</strong>, que por sua vez excluirão aqueles que julgarem ofensivos ou inadequados.
.&nbsp;&nbsp; </li>
</ol>
<p>&nbsp;</p>
<p>A <strong>TARIMBA NA COZINHA</strong> não permite distribuir, vender,  transmitir, transformar e/ou modificar os conteúdos fornecidos no site, principalmente  de forma onerosa, a menos que possua a prévia autorização por escrito do  titular dos correspondentes direitos ou que o faça com permissão legal. </p>
<p>A <strong>TARIMBA NA COZINHA</strong> exibe também conteúdo de terceiros  parceiros, como por exemplo, anúncios, sendo de responsabilidade dos mesmos  eventuais danos causados a partir desses. Assim como, se a <strong>TARIMBA NA  COZINHA</strong> detectar qualquer conteúdo ilícito ou em contrariedade ao seu Termo  de Uso removerá determinado conteúdo e notificará às autoridades competentes.</p>
<p>A <strong>TARIMBA NA COZINHA</strong> contém links que permitem o acesso a  sites de terceiros. No entanto, é importante lembrar que ao acessar ícones ou  links disponibilizados, você estará sujeito aos Termos de Uso e Política de  Privacidade vinculados ao site acessado. A <strong>TARIMBA NA COZINHA</strong> recomenda que você os leia com atenção para garantir o uso saudável e consciente dos  serviços disponibilizados.</p>
<p>Se você for criança&nbsp;ou adolescente&nbsp;ou compartilhar o computador, celular, tablet ou outro aparelho com pessoas nestas condições, lembre-se que é necessária a supervisão dos pais ou responsáveis legais durante  a utilização do site ou a execução de qualquer receita, não importando o grau  de dificuldade.</p>
<p>Todo conteúdo postado ou veiculado no site pela<strong> TARIMBA NA COZINHA</strong> é de sua responsabilidade, cabendo somente a ela permitir, por escrito, a exibição, modificação e comercialização do conteúdo.  </p>
<p>Fica desde já alertado aos usuários que não será permitida a  publicação no site de conteúdo contendo propriedade intelectual de terceiros,  salvo mediante prévia autorização do autor ou proprietário desses direitos,  sendo de responsabilidade única e exclusiva dos usuários as consequências  legais dessas postagens. </p>
<p>É previamente permitida a criação apenas de perfis de usuários pessoas físicas. A criação de perfis de usuários enquanto entidades comerciais e, ou pessoas jurídicas, só é possível se previamente negociada e autorizada pela <strong>TARIMBA NA COZINHA</strong>. 




<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>3. DAS RESPONSABILIDADES</strong></p>
<p><em>&nbsp;</em></p>
<p>A <strong>TARIMBA NA COZINHA</strong>, deve zelar pelas informações fornecidas pelos usuários ou visitantes de modo a notificá-los em caso de  vazamento de dados e tomar as medidas necessárias para sanar os danos, na  medida de sua capacidade de ação, assim como implementação de medidas de  prevenção.</p>
<p>O usuário deve respeitar: as disposições destes termos; os  demais usuários do site; e as informações oferecidas pela <strong>TARIMBA NA COZINHA</strong>,  não divulgando-as sem o consentimento do titular. </p>
<p>A <strong>TARIMBA NA COZINHA</strong> concede a terceiros parceiros espaço  para publicação de anúncios, por isso, é de responsabilidade destes os  conteúdos neles veiculados.</p>
<p>As opiniões dos usuários não refletem a opinião da <strong>TARIMBA NA  COZINHA</strong>, sendo esta apenas uma plataforma de exposição de ideias sobre  culinária e se limitando a este assunto.</p>
<p>A <strong>TARIMBA NA COZINHA</strong> deve remeter à autoridade competente  casos de ilícitos constatados em suas plataformas, assim como contribuir de  todas as formas que forem possíveis e necessárias.</p>
<p>&nbsp;</p>
<p><strong>4. SEGURANÇA</strong></p>
<p>&nbsp;</p>
<p>A <strong>TARIMBA NA COZINHA</strong> preza pela segurança, sigilo e  inviolabilidade de todos os dados cadastrais fornecidos pelos usuários,  coletando, armazenando e transferindo dados de acordo com as normas que versam  sobre o assunto, em especial, a <em><u>Lei nº 12.965 (Marco Civil da Internet</u></em>)  e a <em><u>Lei nº 13.709 (Lei Geral de Proteção de Dados).</u></em></p>
<p>Demais detalhes se encontram na nossa <em><u>Política de Privacidade.</u></em></p>
<p>&nbsp;</p>
<p><strong>5. USO INDEVIDO DO SITE</strong></p>
<p>&nbsp;</p>
<p>Se você fizer mau uso do site, infringindo leis, disposições  deste <em><u>Termo de Uso</u></em> a <strong>TARIMBA NA COZINHA</strong> poderá suspender ou mesmo interromper seu acesso ao site por tempo que julgar  necessário, assim como tomar as medidas cabíveis.</p>
<p>&nbsp;</p>
<p><strong>6. DURAÇÃO DA PRESTAÇÃO DE SERVIÇOS</strong></p>
<p>&nbsp;</p>
<p>O site e os demais serviços têm duração indeterminada, estando  facultado à <strong>TARIMBA NA COZINHA</strong> extingui-los, suspendê-los ou  interrompê-los a qualquer momento. Assim como retornar às atividades normais.</p>
<p>Os problemas oriundos da conexão e da velocidade de transmissão  dos pacotes de dados são de responsabilidade das empresas provedoras de  conexão, que podem interferir nessa transmissão para desobstruir um fluxo de  dados sobrecarregado ou para priorizar serviços de emergência conforme o art.  9º, caput e § 1º, incisos I e II, da <em><u>Lei nº 12.965 (Marco Civil da  Internet</u></em>).<em><u> </u></em></p>
<p>&nbsp;</p>
<p><strong>7. DISPOSIÇÕES GERAIS</strong></p>
<p>&nbsp;</p>
<p>A <strong>TARIMBA NA COZINHA</strong> pode modificar este <em><u>Termo  de Uso</u></em> e termos adicionais referentes ao site, a qualquer tempo e desde que não faça  de forma atentatória aos usuários, cabendo a eles se atualizarem.</p>
<p>A <strong>TARIMBA NA COZINHA</strong> disponibilizará o seu site de modo a  sempre oferecer serviços de qualidade, sendo que quaisquer problemas na  prestação desses serviços, serão solucionados de maneira rápida e efetiva, na  medida do possível.</p>
<p>&nbsp;</p>
<p><strong>8. LEI APLICÁVEL E ELEIÇÃO DE FORO</strong></p>
<p>&nbsp;</p>
<p>Todos os itens deste <em><u>Termo  de Uso</u></em>, <em><u>Política de  Privacidade</u></em> e demais termos do site da <strong>TARIMBA NA COZINHA</strong> são  regidos pelas normas vigentes no ordenamento jurídico brasileiro, em especial  atenção ao <em><u>Marco Civil da Internet</u></em> e à <em><u>Lei Geral de  Proteção de Dados</u></em>. Para todos os assuntos referentes à interpretação,  ao cumprimento ou qualquer outro questionamento relacionado aos termos citados  acima, as partes concordam em se submeter ao Foro da Comarca de São Paulo.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>9. INFORMAÇÕES GERAIS</strong></p>
<p>Caso o visitante ou usuário tenha sugestões, reclamações,  elogios, dúvidas, etc, poderá se comunicar através do e-mail: <a href="mailto:contato@tarimbanacozinha.com.br">contato@tarimbanacozinha.com.br</a></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>

            </div>
                 


               
          </div><!-- col -->

         

        </div>
      </section>




      <!-- -->
  </div>
</template>

<style>

.espacamento{padding:0 40px;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}
.txt-white{color:#FFF;}
</style>

<script>


export default {
  name: 'Termo',
  components: {},
  data(){
    return {
     
    }
  },
  methods:{
   
  },
  computed:{
    
  },
  
  mounted(){
     

  }
}
</script>